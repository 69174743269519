import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header2";
import Footer from "../components/footer";

import "../styles/index.css";
import "../styles/responsive.css";
import aiHead from "../images/homepage/ai-head.svg";
import iotIcon from "../images/homepage/iot-icon_1.svg";
import dataIcon from "../images/homepage/laptops.svg";
import dunavLogo from "../images/homepage/dunav-logo.svg";
import webbLogo from "../images/homepage/webb-logo.svg";
import abagoLogo from "../images/homepage/abago-logo.svg";
import coinisLogo from "../images/homepage/coinis-logo.svg";
import adweLogo from "../images/homepage/adwe-logo.svg";

import logoAnimated from "../images/homepage/logo-animated.svg";

const Hero = (props) => {
    return (
      <div className="hero">
        <div className="container">
          <Header selected="home"/>

          <div className="site-hero">
            <div className="hero-headline">
              <small>Start your</small>
              <h1>Digital Transformation now!</h1>
              <h1>
                <b>We will be your guide!</b>
              </h1>
              <a href="/contact" className="default-btn">
                Contact us
              </a>
            </div>

            <div className="hero-animation">
              <img src={logoAnimated} alt=""/>
            </div>
          </div>
        </div>
      </div>
    );
}

const Services = () => (
  <div className="services">
    <div className="container">
      <h3 className="section-title">Services</h3>
      <div className="service-items">
        <div className="service-half">
          <div className="service-item service-item-ai">
            <img src={aiHead} alt="" />
            <div className="service-item-content">
              <h3>
                <b>
                  Artificial <br /> Intelligence
                </b>
              </h3>
              <p>
                Bring our hard-core research results into <br /> your business.
                <br />
                Employ AI to get the tough job done.
              </p>
              <p>
                Become competitive, build your AI solution <br /> with us today
              </p>
            </div>
          </div>
        </div>

        <div className="service-half service-half-absolute">
          <div className="service-item service-item-data">
            <img src={dataIcon} alt=""/>
            <div className="service-item-content">
              <h3>
                <b>Data Analytics</b>
              </h3>
              <p>
                Boost your revenue in a few simple steps. Put your intuition to
                rest and start making data-driven decisions.
              </p>
              <p>
                Let us help you create new business models and give you better
                insight into your operations.
              </p>
            </div>
          </div>
          <div className="service-item service-item-iot">
            <img src={iotIcon} alt=""/>
            <div className="service-item-content">
              <h3>
                <b>Internet of Things</b>
              </h3>
              <p>
                Transform your physical enviroment into smarter digital
                space.&nbsp;
                <br />
                Get connected, give yourself a chance to act in real-time.&nbsp;
                <br />
                Try our novel, ready-to-use IoT solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Partners = () => (
  <div className="homepage-partners">
    <h3 className="section-title">Friends</h3>
    <div className="partner-list">
      <a rel="nofollow" href="http://webbholding.me/" className="web" target="__blank">
        <img src={webbLogo} alt=""  />
      </a>
      <a rel="nofollow" href="https://www.abago.com/"className="abago" target="__blank">
        <img src={abagoLogo} alt=""/>
      </a>
      <a rel="nofollow" href="https://dunavnet.eu/" className="dunav" target="__blank">
        <img src={dunavLogo} alt="" />
      </a>
      <a rel="nofollow" href="https://coinis.com/" className="coinis" target="__blank">
        <img src={coinisLogo} alt="" />
      </a>
      <a rel="nofollow" href="https://coinis.com/" className="adwe" target="__blank">
        <img src={adweLogo} alt="" />
      </a>
    </div>
  </div>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Homepage" keywords={["bixbit", "web-dev", "iot-solutions"]} />
    <Hero />
    <Services />
    <div className="homepage-bottom-part">
      <div className="container">
        <Partners />
        <Footer />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
